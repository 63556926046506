  import React, { useState, useEffect } from 'react';
  import copyicon from '../../assets/copy_btn.svg';
  import shareicon from '../../assets/share_btn.svg';
  import './Generatepage.css';
  import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import {config} from '../../config';

  const Generatepage = ({ result, onBack, GeneratedResult}) => {
    const [displayedText, setDisplayedText] = useState('');
    const [typing, setTyping] = useState('');

    useEffect(() => {
      if (result) {
        if (GeneratedResult) {
          // Directly show the generated result
          setDisplayedText(result);
          setTyping('');
          logEvent(analytics, 'result_displayed_legal_doc', {
            result_length: result.length,
            generated_result: true
        });
        } else {
          // Use typing effect for the second source result
          setTyping('');
          setDisplayedText(result);

          let index = -1;
          const typingInterval = setInterval(() => {
            if (index < result.length) {
              setTyping(prev => prev + result.charAt(index));
              index++;
            } else {
              clearInterval(typingInterval);
            }
          }, 1); // Adjust typing speed as needed

          return () => clearInterval(typingInterval);
        }
      }
    }, [result, GeneratedResult]);

    useEffect(() => {
      logEvent(analytics, 'Generate_page_view', {
          component_name: 'Generatepage'
      });
  }, []);

    const handleCopy = () => {
      navigator.clipboard.writeText(displayedText);
      alert('Text copied to clipboard!');
    logEvent(analytics, 'text_copied_legal_doc', {
        text_length: displayedText.length
    });
    };

    const handleShare = async () => {
      try {
        if (navigator.share) {
          await navigator.share({
            title: "Generated Legal Document",
            text: displayedText,
          });
          logEvent(analytics, 'document_shared_legal_doc', {
            text_length: displayedText.length
        });
        }
      } catch (error) {
        console.error('Error while sharing:', error);
        alert('Sharing failed. Please try again.');
      }
    };

    return (
      <div className="generate-page-container">
        <div className="generate-topdiv">
          <div className='backbtntext'>
            <button className="back-button" onClick={onBack}>
              <img src={config.Backbutton} alt="Back" />
            </button>
            <span >Generated Document</span> {/* Changed title to "Your Document" */}
          </div>
          <div className="button-container">
            <img src={copyicon} alt="Copy" onClick={handleCopy} />
            <img src={shareicon} alt="Share" onClick={handleShare} />
          </div>
        </div>
        <pre
          id="generate-document-content"
          style={{
            whiteSpace: 'pre-wrap',
            fontFamily: 'Noto Sans',
            padding: '20px',
            borderRadius: '5px'
          }}
        >
          {GeneratedResult ? displayedText : typing}
          
        </pre>
      </div>
    );
  };

  export default Generatepage;
